@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply antialiased;
  font-family: Georgia, serif;
}

.slogan-part {
  @apply inline-block p-5 whitespace-nowrap;
}

.slogan-kanji {
  @apply text-center mr-[-.25em];
  @apply text-9xl font-serif font-thin tracking-[.25em] leading-relaxed;
}

.slogan-alphabet {
  @apply text-center mr-[-1.75em] mt-1;
  @apply text-2xl italic font-bold font-serif font-light tracking-[1.75em] leading-loose;
}